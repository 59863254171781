import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

import i18n, {
  AppLanguages,
  appLanguageToCode,
  languageCodeToShort,
  languageCodeToSupportedLanguage,
  SupportedAppLanguage
} from '@app/locales/i18n'

import { languageToFlagIcon } from '../../utils/languageToFlagIcon'

type Props = {
  onLanguageChange?: (language: SupportedAppLanguage) => void
  appLanguage?: SupportedAppLanguage
}

export const LanguageSelector = ({
  onLanguageChange,
  appLanguage = languageCodeToSupportedLanguage[i18n.language]
}: Props) => {
  const { t } = useTranslation('common')

  const [selectedLanguage, setSelectedLanguage] = useState<AppLanguages>(appLanguageToCode[appLanguage])
  const Icon = languageToFlagIcon[languageCodeToSupportedLanguage[selectedLanguage]]

  return (
    <div className="flex items-center">
      {Icon && <Icon />}
      <select
        onChange={async (e) => {
          const newLanguageCode = e.target.value as AppLanguages
          const newLanguage = languageCodeToSupportedLanguage[newLanguageCode]

          await i18n.changeLanguage(newLanguageCode)
          dayjs.locale(languageCodeToShort[newLanguageCode] || 'cs')
          setSelectedLanguage(newLanguageCode)
          localStorage.setItem('lng', newLanguage)
          onLanguageChange?.(newLanguage)
        }}
        defaultValue={selectedLanguage}
        className="rounded-md bg-[transparent] p-1 text-white focus:outline-none"
      >
        <option className="text-black hover:text-white" value={'en-US'}>
          {t('languages.english')}
        </option>
        <option className="text-black hover:text-white" value={'cs-CZ'}>
          {t('languages.czech')}
        </option>
      </select>
    </div>
  )
}
