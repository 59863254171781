import React, { ReactElement, useEffect } from 'react'
import ReactGA from 'react-ga4'
import { useKeycloak } from '@react-keycloak/web'

import { useTimeout } from '@foxino/components-common'

import { Loader } from '@app/components'

import { logout } from '../actions/authActions'

type Props = {
  children: React.ReactNode | React.ReactNode[] | null
}

const LOGOUT_TIMER = 5_000

export const AuthProvider = ({ children }: Props): ReactElement | null => {
  const { keycloak } = useKeycloak()
  const [callLogoutTimer, isWaiting, clearLogoutTimer] = useTimeout(LOGOUT_TIMER)

  // Logout user when keycloak is initialized, but not authenticated
  // This is edge case where session needs to be cleared and logged in again
  useEffect(() => {
    if (!keycloak.authenticated) {
      if (isWaiting) callLogoutTimer(logout)
    } else {
      clearLogoutTimer()

      ReactGA.set({ userId: keycloak.tokenParsed?.['preferred_username'] || '', userAgent: navigator.userAgent })
    }
  }, [isWaiting, keycloak.authenticated])

  if (!keycloak.authenticated) {
    return (
      <div className="flex h-[100vh] w-full items-center justify-center">
        <Loader />
      </div>
    )
  }

  return <>{children}</>
}
