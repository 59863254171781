import { CzechFlagIcon } from '@app/assets/flagIcons/flat/CzechFlagIcon'
import { EnglishFlagIcon } from '@app/assets/flagIcons/flat/EnglishFlagIcon'
import { EnglishFlagRoundIcon } from '@app/assets/flagIcons/round/EnglishFlagRoundIcon'
import { FranceFlagRoundIcon } from '@app/assets/flagIcons/round/FranceFlagRoundIcon'
import { GermanFlagRoundIcon } from '@app/assets/flagIcons/round/GermanFlagRoundIcon'
import { ItalyFlagRoundIcon } from '@app/assets/flagIcons/round/ItalyFlagRoundIcon'
import { SpanishFlagRoundIcon } from '@app/assets/flagIcons/round/SpanishFlagRoundIcon'
import { Language } from '@app/data'

// TODO: replace with square flag
export const languageToFlagIcon: Record<Language, typeof EnglishFlagRoundIcon> = {
  ENGLISH: EnglishFlagIcon,
  CZECH: CzechFlagIcon,
  FRENCH: FranceFlagRoundIcon,
  GERMAN: GermanFlagRoundIcon,
  ITALIAN: ItalyFlagRoundIcon,
  SPANISH: SpanishFlagRoundIcon
}
