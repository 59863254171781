/* eslint-disable react-refresh/only-export-components */
import React from 'react'
import { createBrowserRouter } from 'react-router-dom'

import { Subject } from '@app/data'
import { BasicLayout, PageNotExist } from '@app/modules/common/view'
import { MaintenancePage } from '@app/modules/common/view/MaintenancePage'

import { ErrorElement } from './ErrorElement'
import { Pages } from './PagesEnum'

const ActivitiesPage = React.lazy(() => import('@app/modules/activities/view/ActivitiesPage/ActivitiesPage'))
const ClassroomPage = React.lazy(() => import('@app/modules/classroom/view/ClassroomPage/ClassroomPage'))
const ClassroomPerformancePage = React.lazy(
  () => import('@app/modules/classPerformance/view/ClassroomPerformancePage/ClassroomPerformancePage')
)
const CollectionDetailPage = React.lazy(
  () => import('@app/modules/library/view/CollectionDetailPage/CollectionDetailPage')
)
const DictionaryPage = React.lazy(() => import('@app/modules/library/view/DictionaryPage/DictionaryPage'))
const LearningPlanPage = React.lazy(() => import('@app/modules/learningPlan/view/LearningPlanPage/LearningPlanPage'))
const LibraryPage = React.lazy(() => import('@app/modules/library/view/LibraryPage/LibraryPage'))
const ManageClassPage = React.lazy(() => import('@app/modules/classroom/view/ManageClassPage/ManageClassPage'))
const NewClassPage = React.lazy(() => import('@app/modules/classroom/view/NewClassPage/NewClassPage'))
const QuizGameEditorPage = React.lazy(() => import('@app/modules/games/pages/QuizGameEditor/QuizGameEditor'))
const SettingsPage = React.lazy(() => import('@app/modules/settings/view/SettingsPage/SettingsPage'))

export const router = createBrowserRouter([
  {
    path: Pages.ROOT,
    element: <BasicLayout />,
    errorElement: <ErrorElement />,
    children: [
      {
        index: true,
        element: <ClassroomPage />
      },
      {
        path: Pages.NEW_CLASS,
        element: <NewClassPage />
      },
      {
        path: Pages.CLASSROOM_DETAIL(':id'),
        element: <ClassroomPage />,
        children: [
          {
            index: true,
            element: <ClassroomPage />
          },
          {
            path: Pages.CLASSROOM_LEARNING_PLAN(':id'),
            element: <LearningPlanPage />
          },
          {
            path: Pages.CLASSROOM_QUIZ_EDITOR(':id', ':activityId', ':subject' as Subject),
            element: <QuizGameEditorPage />
          },
          {
            path: Pages.CLASSROOM_ACTIVITIES(':id'),
            element: <ActivitiesPage />
          },
          {
            path: Pages.CLASSROOM_PERFORMANCE(':id'),
            element: <ClassroomPerformancePage />
          },
          {
            path: Pages.CLASSROOM_MANAGE_CLASS(':id'),
            element: <ManageClassPage />
          }
        ]
      },
      {
        path: Pages.SETTINGS,
        element: <SettingsPage />
      },
      {
        path: Pages.DICTIONARY(':language'),
        element: <DictionaryPage />
      },
      {
        path: Pages.LIBRARY(':language'),
        element: <LibraryPage />
      },
      {
        path: Pages.LIBRARY_COLLECTION_DETAIL(':language', ':id'),
        element: <CollectionDetailPage />
      }
    ]
  },
  {
    path: Pages.MAINTENANCE,
    element: <BasicLayout />,
    children: [{ index: true, element: <MaintenancePage /> }]
  },
  {
    path: '*',
    element: <PageNotExist />
  }
])
